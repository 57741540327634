<template>
  <!--begin::Offices-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.OFFICES_TITLE") }}
          <span style="max-width: 350px;"
                class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.OFFICES_TEXT_DESC") }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <b-button v-if="isCanEdit"
                  v-b-modal.modal-add-edit
                  class="btn bg-light-primary font-weight-bolder font-size-sm">
          <i class="fas fa-plus icon-md"></i>{{ $t("MANAGEMENT.ADD_OFFICE_BUTTON") }}
        </b-button>
        <!--end::Button-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isLoading" class="card-body">
      <!--begin::Dummy-->
      <template v-if="officesList.length === 0">
        <div class="justify-content-center row">
          <div class="col-auto d-flex">
            <OfficesImage/>
          </div>
        </div>
        <div class="justify-content-center row">
          <h5 class="col-auto font-weight-bolder text-dark my-5 text-center">{{ $t("MANAGEMENT.NO_OFFICE_YET_TITLE") }}
            <span v-if="isCanEdit" class="d-block font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.NO_OFFICE_YET_TITLE_DESC") }}</span>
            <b-button v-if="isCanEdit"
                      v-b-modal.modal-add-edit
                      @click="onAddOffice"
                      class="btn bg-primary text-light font-weight-bolder my-4">{{ $t("MANAGEMENT.ADD_OFFICE_BUTTON") }}
            </b-button>
          </h5>
        </div>
      </template>
      <!--end::Dummy-->
      <!--begin::Table-->
      <div v-if="officesList.length > 0" class="table-responsive">
        <b-table
            id="offices-table"
            class="table-vertical-center"
            show-empty
            :items="officesList"
            :fields="fields">
          <template #empty="scope">
            <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
          </template>
          <template #head()="data">
            <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
          </template>
          <template #head(actions)="data">
            <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
          </template>
          <template #cell(name)="data">
            <span @click="setAmplitudeEventType('management-office-floor show')">
              <router-link :disabled="!isCanFloor"
                           :event="isCanFloor ? 'click' : ''"
                           :style="isCanFloor ? 'text-decoration: underline': ''"
                  :to="{ name: 'office', params: { id: data.item.id } }">
                <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
              </router-link>
            </span>
          </template>
          <template #cell(floor)="data">
            <span>{{ data.item.floorsAmount }}</span>
          </template>
          <template #cell(address)="data">
            <p class="font-size-md mb-0">{{ data.item.displayAddress }}</p>
          </template>
          <template #cell(actions)="data">
          <span v-if="isCanEdit" class="float-right">
            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      @click="onEditOffice(data.item)"
                      v-b-modal.modal-add-edit
                      v-b-tooltip.hover.v-dark="$t('MANAGEMENT.EDIT_TOOLTIP_TEXT')">
              <Edit/>
            </b-button>
            <span @click="setAmplitudeEventType('management-office-floor show')">
              <router-link class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                           v-b-tooltip.hover.v-dark="$t('MANAGEMENT.SETTINGS_FLOORS_TOOLTIP_TEXT')"
                           :to="{ name: 'office', params: { id: data.item.id } }">
                <Settings/>
              </router-link>
            </span>
            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      @click="onDeleteOffice(data.item)"
                      v-b-modal.modal-delete
                      v-b-tooltip.hover.v-dark="$t('MANAGEMENT.DELETE_TOOLTIP_TEXT')">
              <Trash/>
            </b-button>
          </span>
          </template>
        </b-table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--begin::Add Edit Modal-->
    <AddEditOfficeModal
        :citiesOfCountry="citiesOfCountry"
        :onPage="'offices'"
        :form="form"
        :isEdit="isEdit"
        @changeSelectedCountry="newCitiesList"
        @changeIsEdit="toggleIsEdit"
        @changeForm="clearForm"/>
    <!--end::Add Edit Modal-->
    <!--begin::Delete Modal-->
    <b-modal
        id="modal-delete"
        centered>
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_OFFICE_TITLE") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_OFFICE_DESC") }}</h5>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="danger"
                @click="handleDelete">
              {{ $t("MANAGEMENT.DELETE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--begin::Delete Modal-->
  </div>
  <!--end::Offices-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_OFFICES_LIST,
  GET_COUNTRY_CITY_LIST,
  GET_TIMEZONES_LIST,
  DELETE_OFFICE
} from "@/core/services/store/management.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";

import OfficesImage from "@/view/layout/svg/OfficesImage";
import Edit from "@/view/layout/svg/Edit";
import Settings from "@/view/layout/svg/Settings";
import Trash from "@/view/layout/svg/Trash";
import AddEditOfficeModal from "@/view/layout/modal/AddEditOfficeModal";

export default {
  name: "Offices",
  components: {
    OfficesImage,
    Edit,
    Settings,
    Trash,
    AddEditOfficeModal
  },
  mounted() {
    this.$store.dispatch(GET_OFFICES_LIST).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MANAGEMENT.OFFICES_TITLE") }]);
      this.isLoading = true
    });
    this.$store.dispatch(GET_COUNTRY_CITY_LIST, { params: { is_use:  0 } });
    this.$store.dispatch(GET_TIMEZONES_LIST);
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      form: {
        name: '',
        address: {
          country: null,
          city: null,
          rest: ''
        },
        timezone: null
      },
      citiesOfCountry: null,
      nameState: null,
      countryState: null,
      cityState: null,
      addressState: null,
      timezoneState: null,
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.NAME_TABLE_TITLE") },
        { key: 'floor', label: this.$t("MANAGEMENT.FLOOR_TABLE_TITLE"), thClass: 'text-center', tdClass: 'text-center' },
        { key: 'address', label: this.$t("MANAGEMENT.ADDRESS_TABLE_TITLE") },
        { key: 'actions', label: this.$t("MANAGEMENT.ACTIONS_TABLE_TITLE") }
      ],
      deleteFormId: null
    }
  },
  computed: {
    ...mapGetters([
      "officesList",
      "countryCityList",
      "timezonesList"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    },
    isCanFloor() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[3]));
        return role !== undefined;
      }
    },
  },
  methods: {
    setAmplitudeEventType(eventType) {
      sendAmplitudeEvent(eventType);
    },
    newCitiesList(variable) {
      this.citiesOfCountry = this.countryCityList.find(country => country.id === variable);
      this.citiesOfCountry = this.citiesOfCountry.cities;
    },
    toggleIsEdit(variable) {
      this.isEdit = variable;
    },
    clearForm(variable) {
      this.form = variable;
    },
    onAddOffice() {
      this.isEdit = false;
    },
    onEditOffice(item) {
      this.isEdit = true;
      this.form = item;
      if (this.countryCityList.length !== undefined && this.form !== undefined) {
        let country = this.countryCityList.find(country => country.country === this.form.address.country.name);
        if (country) {
          this.form.address.country = country.id;
          let city = country.cities.find(city => city.name === this.form.address.city.name);
          this.form.address.city = city.id;
          this.citiesOfCountry = country.cities;
        }
      }
      if (this.timezonesList.length > 0 && this.form !== undefined) {
        let timezone = this.timezonesList.find(item => item.name.includes(this.form.timezone.slice(-12)));
        if (timezone) this.form.timezone = timezone.id;
      }
    },
    onDeleteOffice(item) {
      this.deleteFormId = item.id;
    },
    resetModal() {
      this.deleteFormId = null;
      this.$bvModal.hide('modal-delete');
    },
    handleDelete() {
      this.$store.dispatch(DELETE_OFFICE, this.deleteFormId)
        .then(() => {
          localStorage.removeItem("floorFilter");
          this.$store.dispatch(GET_OFFICES_LIST)
          this.deleteFormId = null
        });
      this.$nextTick(() => {
        this.$bvModal.hide('modal-delete');
      })
    }
  }
}
</script>

<style scoped>

</style>
