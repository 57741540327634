<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="353.494" height="200" viewBox="0 0 353.494 200">
    <defs>
      <clipPath id="clip-path">
        <path id="Контур_112" data-name="Контур 112" d="M27.317-1370.281s-41.41-50.406-21.172-102.3c13.191-33.825,62.675-52.359,90.6-33.825,11.894,7.894,50.053.266,70.427-32.416,39.594-63.509,177.774-24.939,152.618,72.57-12.174,47.187,37.05,48.9,11.969,96.071l-304.443-.1" transform="translate(-0.574 1569.903)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Контур_111" data-name="Контур 111" d="M-224-1524.048H183.923V-1779H-224Z" transform="translate(224 1779)" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-3">
        <path id="Контур_115" data-name="Контур 115" d="M1480.531-417.556l-.107,0a1.453,1.453,0,0,1-1.47-1.554l5.84-58.318,5.1.454-8.384,58.633a.95.95,0,0,1-.974.788" transform="translate(-1478.948 477.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-5">
        <path id="Контур_118" data-name="Контур 118" d="M1868.4-417.556l.107,0a1.451,1.451,0,0,0,1.469-1.554l-5.838-58.318-5.095.454,8.384,58.633a.949.949,0,0,0,.974.788" transform="translate(-1859.04 477.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-7">
        <path id="Контур_121" data-name="Контур 121" d="M1157.369-417.556l-.107,0a1.452,1.452,0,0,1-1.469-1.554l5.839-58.318,5.1.454-8.385,58.633a.949.949,0,0,1-.974.788" transform="translate(-1155.786 477.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-9">
        <path id="Контур_124" data-name="Контур 124" d="M1566.537-984l10.365-51.187c1.331-6.931-4.412-13.267-11.869-13.1l-19.99.457a15.792,15.792,0,0,0-14.809,11.4L1515.07-984h51.467" transform="translate(-1515.07 1048.288)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-11">
        <path id="Контур_127" data-name="Контур 127" d="M1517.347-984l10.365-51.187c1.331-6.931-4.412-13.267-11.869-13.1l-19.99.457a15.791,15.791,0,0,0-14.809,11.4L1465.88-984h51.467" transform="translate(-1465.88 1048.288)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-13">
        <path id="Контур_130" data-name="Контур 130" d="M1335.039-534.644h-79.09a2.9,2.9,0,0,1-2.889-2.89v-3.558a2.9,2.9,0,0,1,2.889-2.889h81.979v6.446a2.9,2.9,0,0,1-2.889,2.89" transform="translate(-1253.06 543.98)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-15">
        <path id="Контур_133" data-name="Контур 133" d="M1243.976-534.644h-50.418a2.335,2.335,0,0,1-2.328-2.328v-4.681a2.335,2.335,0,0,1,2.328-2.328h50.418a2.334,2.334,0,0,1,2.326,2.328v4.681a2.334,2.334,0,0,1-2.326,2.328" transform="translate(-1191.23 543.98)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-17">
        <path id="Контур_136" data-name="Контур 136" d="M366.522-11.031H15.92a.5.5,0,0,1-.5-.5V-12a.5.5,0,0,1,.5-.5h350.6a.5.5,0,0,1,.5.5v.469a.5.5,0,0,1-.5.5" transform="translate(-15.42 12.5)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-19">
        <path id="Контур_139" data-name="Контур 139" d="M1273.581-1256.716c-1.114-.912,23.174-21.416,28.381-9.92,0,0,12.472-9.609.988-15.233-6.466-3.166-7.394-9.828-8.927-13.748s-9.283-14.613-18.229-7.1c0,0-4.542.8-6.987,3.793-5.562,6.8-17.926,52.245,4.774,42.206" transform="translate(-1260.416 1305.328)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-21">
        <path id="Контур_142" data-name="Контур 142" d="M1304.42-1080.507a79.851,79.851,0,0,1-10.143,13.895c-3.128,3.328-6.731,6.3-10.433,7.59a10.754,10.754,0,0,1-5.448.547c-1.156-.2-.654-7.025.952-12.211,2.115-6.831,8.709-23.807,8.709-23.807l3.773,1.609s-5.234,21.379-4.609,21.47l5.534-14,11.665,4.911" transform="translate(-1277.765 1094.492)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-23">
        <path id="Контур_145" data-name="Контур 145" d="M1213.137-713.381s-42.05-.669-47.661,2.9,5.608,60.549,5.608,60.549,10.876.347,11.74-2.532c0,0-1.957-26.7-.245-31.311s.657-11.005.657-11.005,21.272,3.246,28.027,1.648,1.874-20.248,1.874-20.248" transform="translate(-1163.905 713.431)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-25">
        <path id="Контур_148" data-name="Контур 148" d="M1234.773-273.072s2.394,6.408,1.885,8.786.312,6.984-.66,9.046-2.711,5.056-1.043,5.814c.632.288,8-5.424,8.417-7.109s.751-6.657,2.161-9.357a4.013,4.013,0,0,0-1.81-5.869c-2.037-.992-4.876-6.429-4.876-6.429l-4.074,5.118" transform="translate(-1234.26 278.19)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-27">
        <path id="Контур_151" data-name="Контур 151" d="M1077.969-168.809s-2.306,6.44-4.228,7.929-4.268,5.538-6.342,6.485-5.332,2.114-4.547,3.769c.3.628,9.608,1.015,11.018,0s4.868-4.6,7.687-5.756a4.011,4.011,0,0,0,2.4-5.651c-.915-2.073.423-8.058.423-8.058l-6.415,1.281" transform="translate(-1062.732 170.09)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-29">
        <path id="Контур_154" data-name="Контур 154" d="M1238.757-724.79s-49.9,7.809-53.745,12.249-19.072,62.278-19.072,62.278,4.444,2.492,8.5.186c0,0,11.593-19.6,16.713-25.753s7.086-25.175,7.93-25.368,27.7,3.244,37.589-3.3c5.831-3.859,2.089-20.29,2.089-20.29" transform="translate(-1165.94 724.79)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-31">
        <path id="Контур_157" data-name="Контур 157" d="M1383.814-1072.959c-2.945-2.217-11.807-4.665-17.35-2.171-2.625,1.181-6.607,2.729-8.991,5.69-2.573,3.2-5.534,14-5.534,14,.968,5.693,5.494,6.39,5.494,6.39-2.65,10.021,9.837,7.4,9.03,12.961-1.409,9.7,7.664,14.79,12.607,17.05,6.471,2.959,24.656,6.686,24.676,0,.089-30.459-11.467-47.551-19.934-53.925" transform="translate(-1351.94 1076.18)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-33">
        <path id="Контур_160" data-name="Контур 160" d="M1453.612-966.151s-3.279,17.7-12.163,22.9h-8.36l8.825-11.03,11.7-11.867" transform="translate(-1433.09 966.151)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-35">
        <path id="Контур_163" data-name="Контур 163" d="M1338.086-1144.679a43.064,43.064,0,0,1-3.192-4.213c-.475-.98.416-5.2,1.522-5.171.7.017,1.558,2.573,2.322,3.5s4.084,2.251,2.679,6.156c-.158.44-3.331-.273-3.331-.273" transform="translate(-1334.763 1154.064)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-37">
        <path id="Контур_166" data-name="Контур 166" d="M1402.172-1131.508c-.766,1.149.168,4.37,4.51,3.367,2.851-.658,8.4-5.281,7.4-7.64-.476-1.121-3.209-3.754-4.926-10.6l-.5.236-8.36,3.986s.906,2.719,1.577,5.328c.565,2.19.611,4.847.295,5.322" transform="translate(-1400.3 1146.38)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-39">
        <path id="Контур_169" data-name="Контур 169" d="M1400.3-1140.539s.906,2.719,1.577,5.328c4.891-1.394,6.35-6.551,6.783-9.313l-8.36,3.986" transform="translate(-1400.3 1144.525)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-41">
        <path id="Контур_172" data-name="Контур 172" d="M1350.376-1233.712s11.718,2.719,13.3-4.6,4.157-11.9-3.257-13.949-9.475.434-10.6,2.75-2.45,14.908.557,15.8" transform="translate(-1348.536 1253.026)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-43">
        <path id="Контур_175" data-name="Контур 175" d="M1400.03-1263.594s.074,8.428,6.28,12.518c3.632,2.394,3.8,4.843,3.8,4.843a69.916,69.916,0,0,0,2.492-12.635s-9.268-8.294-12.574-4.725" transform="translate(-1400.03 1264.486)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-45">
        <path id="Контур_178" data-name="Контур 178" d="M1353.32-1262.262a20.327,20.327,0,0,0-5,2.427c-2.282,1.888-2.876,8.029-2.6,9.558,0,0-.621-10.672,2.815-13.391,3.642-2.883,4.793,1.407,4.793,1.407" transform="translate(-1345.651 1264.665)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-47">
        <path id="Контур_181" data-name="Контур 181" d="M1454.895-1040.113a61.266,61.266,0,0,1-4.036,7.94c-5.592,9.245-12.43,14.83-14.9,12.227-.683-.72-2.407-1.766-.6-5.637,1.392-2.99,3.115-6.73,4.807-10.411,3.488-7.589,6.833-14.925,6.833-14.925,1.066,0,12.269.39,7.9,10.807" transform="translate(-1434.559 1050.92)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-49">
        <path id="Контур_184" data-name="Контур 184" d="M1297.652-873.113c-.384-.229-21.466,4.891-28,6.484l-1.907.466v2.46s21.815,1.024,25.223-.541a24.849,24.849,0,0,0,6.659-5.206l-1.98-3.664" transform="translate(-1267.75 873.121)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-51">
        <path id="Контур_187" data-name="Контур 187" d="M1493.253-1040.113a61.327,61.327,0,0,1-4.036,7.94,31.09,31.09,0,0,1-10.7-3.821c3.488-7.589,6.833-14.925,6.833-14.925,1.066,0,12.268.39,7.9,10.807" transform="translate(-1478.52 1050.92)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-53">
        <path id="Контур_190" data-name="Контур 190" d="M966.88-811.988H990.3a1.866,1.866,0,0,0-1.866-1.867H966.88v1.867" transform="translate(-966.88 813.855)" fill="#36364b" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-54">
        <path id="Контур_189" data-name="Контур 189" d="M-224-1524.048H183.923V-1779H-224Z" transform="translate(224 1779)" fill="#36364b"/>
      </clipPath>
      <clipPath id="clip-path-55">
        <path id="Контур_193" data-name="Контур 193" d="M719.584-999.29H683.377l8.685,25.505H728.5l-8.919-25.505" transform="translate(-683.377 999.29)" fill="#262636" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-56">
        <path id="Контур_192" data-name="Контур 192" d="M-224-1524.048H183.923V-1779H-224Z" transform="translate(224 1779)" fill="#262636"/>
      </clipPath>
      <clipPath id="clip-path-57">
        <path id="Контур_196" data-name="Контур 196" d="M687.88-973.788h36.442L715.4-999.294H679.195Z" transform="translate(-679.195 999.294)" fill="#36364b" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-59">
        <path id="Контур_199" data-name="Контур 199" d="M832.008-913.178a2.353,2.353,0,0,0,2.171,1.765,1.359,1.359,0,0,0,1.358-1.765,2.353,2.353,0,0,0-2.172-1.764,1.358,1.358,0,0,0-1.356,1.764" transform="translate(-831.961 914.942)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-61">
        <path id="Контур_202" data-name="Контур 202" d="M2027.41-477.735v1.484c0,.52.168.945.373.945h54.17c.205,0,.374-.424.374-.945v-1.484c0-.519-.168-.945-.374-.945h-54.17c-.205,0-.373.426-.373.945" transform="translate(-2027.41 478.68)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-63">
        <path id="Контур_205" data-name="Контур 205" d="M2027.41-477.735v1.484c0,.52.154.945.343.945h43c.188,0,.343-.424.343-.945v-1.484c0-.519-.154-.945-.343-.945h-43c-.189,0-.343.426-.343.945" transform="translate(-2027.41 478.68)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-65">
        <path id="Контур_208" data-name="Контур 208" d="M2134.96-396.166h38.457V-452.22H2134.96Z" transform="translate(-2134.96 452.22)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-67">
        <path id="Контур_211" data-name="Контур 211" d="M2050.42-396.166h38.457V-452.22H2050.42Z" transform="translate(-2050.42 452.22)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-69">
        <path id="Контур_214" data-name="Контур 214" d="M2066.991-388.787h33.582V-439.42h-33.582Zm-.371.371h34.324V-439.79H2066.62Z" transform="translate(-2066.62 439.79)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-71">
        <path id="Контур_217" data-name="Контур 217" d="M2068.08-306.1h33.954v-.37H2068.08Z" transform="translate(-2068.08 306.47)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-73">
        <path id="Контур_220" data-name="Контур 220" d="M2068.08-170.18h33.954v-.37H2068.08Z" transform="translate(-2068.08 170.55)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-75">
        <path id="Контур_223" data-name="Контур 223" d="M2170.156-421.417h6.3a.559.559,0,0,0,.557-.556.559.559,0,0,0-.557-.557h-6.3a.558.558,0,0,0-.556.557.558.558,0,0,0,.556.556" transform="translate(-2169.6 422.53)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-77">
        <path id="Контур_226" data-name="Контур 226" d="M2170.156-288.5h6.3a.558.558,0,0,0,.557-.556.558.558,0,0,0-.557-.556h-6.3a.557.557,0,0,0-.556.556.557.557,0,0,0,.556.556" transform="translate(-2169.6 289.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-79">
        <path id="Контур_229" data-name="Контур 229" d="M2170.156-149.947h6.3a.559.559,0,0,0,.557-.557.559.559,0,0,0-.557-.556h-6.3a.558.558,0,0,0-.556.556.558.558,0,0,0,.556.557" transform="translate(-2169.6 151.06)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-81">
        <path id="Контур_232" data-name="Контур 232" d="M1800.528-1395.356h-22a1.842,1.842,0,0,1-1.837-1.836v-21.235a1.843,1.843,0,0,1,1.837-1.836h22a1.843,1.843,0,0,1,1.837,1.836v21.235a1.842,1.842,0,0,1-1.837,1.836" transform="translate(-1776.69 1420.264)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-83">
        <path id="Контур_235" data-name="Контур 235" d="M1807.192-1389.05H1787.1a1.682,1.682,0,0,1-1.677-1.677v-19.4a1.682,1.682,0,0,1,1.677-1.677h20.094a1.682,1.682,0,0,1,1.677,1.677v19.4a1.682,1.682,0,0,1-1.677,1.677" transform="translate(-1785.42 1411.8)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-85">
        <path id="Контур_238" data-name="Контур 238" d="M1873.22-1397.607a.532.532,0,0,1,.532-.532.532.532,0,0,1,.533.532.532.532,0,0,1-.533.532.532.532,0,0,1-.532-.532" transform="translate(-1873.22 1398.139)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-87">
        <path id="Контур_241" data-name="Контур 241" d="M1906.43-1389.943a.531.531,0,0,1,.723-.207.532.532,0,0,1,.207.723.532.532,0,0,1-.723.207.531.531,0,0,1-.207-.723" transform="translate(-1906.362 1390.216)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-89">
        <path id="Контур_244" data-name="Контур 244" d="M1934.058-1365.739a.532.532,0,0,1,.735.159.533.533,0,0,1-.159.735.532.532,0,0,1-.735-.159.532.532,0,0,1,.159-.735" transform="translate(-1933.814 1365.824)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-91">
        <path id="Контур_247" data-name="Контур 247" d="M1946.372-1329.692a.532.532,0,0,1,.533.532.532.532,0,0,1-.533.532.532.532,0,0,1-.532-.532.532.532,0,0,1,.532-.532" transform="translate(-1945.84 1329.692)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-93">
        <path id="Контур_250" data-name="Контур 250" d="M1936.506-1289.6a.532.532,0,0,1,0,.752.532.532,0,0,1-.752,0,.532.532,0,0,1,0-.752.532.532,0,0,1,.752,0" transform="translate(-1935.598 1289.757)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-95">
        <path id="Контур_253" data-name="Контур 253" d="M1910.305-1263.334a.531.531,0,0,1,0,.752.532.532,0,0,1-.752,0,.532.532,0,0,1,0-.752.532.532,0,0,1,.752,0" transform="translate(-1909.397 1263.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-97">
        <path id="Контур_256" data-name="Контур 256" d="M1873.22-1252.369a.532.532,0,0,1,.532-.532.532.532,0,0,1,.533.532.532.532,0,0,1-.533.532.532.532,0,0,1-.532-.532" transform="translate(-1873.22 1252.901)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-99">
        <path id="Контур_259" data-name="Контур 259" d="M1840.949-1389.943a.531.531,0,0,0-.723-.207.531.531,0,0,0-.207.723.531.531,0,0,0,.723.207.532.532,0,0,0,.206-.723" transform="translate(-1839.952 1390.216)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-101">
        <path id="Контур_262" data-name="Контур 262" d="M1813.314-1365.739a.532.532,0,0,0-.736.159.532.532,0,0,0,.159.735.531.531,0,0,0,.734-.159.531.531,0,0,0-.158-.735" transform="translate(-1812.494 1365.824)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-103">
        <path id="Контур_265" data-name="Контур 265" d="M1801-1329.692a.532.532,0,0,0-.532.532.532.532,0,0,0,.532.532.531.531,0,0,0,.532-.532.531.531,0,0,0-.532-.532" transform="translate(-1800.47 1329.692)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-105">
        <path id="Контур_268" data-name="Контур 268" d="M1810.873-1289.6a.532.532,0,0,0,0,.752.532.532,0,0,0,.752,0,.532.532,0,0,0,0-.752.532.532,0,0,0-.752,0" transform="translate(-1810.718 1289.757)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-107">
        <path id="Контур_271" data-name="Контур 271" d="M1837.07-1263.334a.534.534,0,0,0,0,.752.533.533,0,0,0,.753,0,.534.534,0,0,0,0-.752.533.533,0,0,0-.753,0" transform="translate(-1836.915 1263.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-109">
        <path id="Контур_274" data-name="Контур 274" d="M1876.27-1329.16a.532.532,0,0,1,.532-.532.531.531,0,0,1,.532.532.531.531,0,0,1-.532.532.532.532,0,0,1-.532-.532" transform="translate(-1876.27 1329.692)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-111">
        <path id="Контур_277" data-name="Контур 277" d="M1879.87-1371h.145v-6.644h-.145Z" transform="translate(-1879.87 1377.639)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-113">
        <path id="Контур_280" data-name="Контур 280" d="M1879.129-1345.52l-.269-.343,3.522-2.765.269.343-3.522,2.765" transform="translate(-1878.86 1348.628)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-115">
        <path id="Контур_283" data-name="Контур 283" d="M306.788-424.826s6.3-7.354.389-12.026c0,0-4.32-2.615-7.843-2.4,0,0,4.992,8.371,3.377,9.2s-4.89-9.729-4.89-9.729-9.832-.954-12.995.567c0,0,8.4,6.879,6.635,7.288s-10.848-6.342-10.848-6.342-9.15,1.558-8.662,7.14,3.641,7.306,5.027,7.158,9.492-3.243,9.669-2.108-4.7,4.847-8.12,5.02c0,0,6.451,7.114,10.739,5.062s4.654-5.308,7.326-6.529,4.954-1.217,3.851.027-5.941,2.455-7.469,5.159-2.9,4.527,1.536,3.86,11.647-3.914,11.963-7.631l.317-3.716" transform="translate(-271.931 440.075)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-117">
        <path id="Контур_286" data-name="Контур 286" d="M307.349-369s24.853-.957,30.342,6.151a17.978,17.978,0,0,0,4.949,6.945l.582,1.587s-4.307-4.541-5.848-4.816c0,0,2.41-8.806-30.026-9.867" transform="translate(-307.349 369.038)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-119">
        <path id="Контур_289" data-name="Контур 289" d="M574.591-270.251a15.607,15.607,0,0,1,5.705,10.995l-.968.087s-1.7-7.289-4.783-10.139.046-.942.046-.942" transform="translate(-573.187 270.876)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-121">
        <path id="Контур_292" data-name="Контур 292" d="M673.138-570.73s-10.027-5.615-5.348-13.236c0,0,3.744-4.68,7.754-5.883,0,0-2.139,11.365,0,11.631s1.47-12.834,1.47-12.834,10.563-5.08,14.707-4.68c0,0-6.551,11.1-4.412,10.83s9.492-11.5,9.492-11.5,10.829-2.005,12.568,4.412-1.069,9.626-2.674,10.027-11.9.268-11.631,1.6,7.219,3.476,11.1,2.273c0,0-4.278,10.563-9.893,10.027s-7.354-4.01-10.829-4.278-6.017.669-4.279,1.6,7.622.306,10.429,2.694,5.08,3.857-.134,4.927-14.573.4-16.444-3.61l-1.872-4.01" transform="translate(-666.546 596.697)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-123">
        <path id="Контур_295" data-name="Контур 295" d="M731.24-544.03s-28.075,9.092-31.284,19.253a21.326,21.326,0,0,1-2.674,9.76v2.005s2.941-6.819,4.546-7.754c0,0-6.284-8.824,29.412-23.263" transform="translate(-697.282 544.03)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-125">
        <path id="Контур_298" data-name="Контур 298" d="M679.335-324.983a18.517,18.517,0,0,0-1.861,14.578l1.114-.3s-1.081-8.815,1.184-13.249-.437-1.03-.437-1.03" transform="translate(-676.713 326.232)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-127">
        <path id="Контур_301" data-name="Контур 301" d="M261.273-688.03s13.218-7.4,7.049-17.448c0,0-4.935-6.168-10.222-7.754,0,0,2.82,14.98,0,15.333s-1.939-16.919-1.939-16.919-13.923-6.7-19.386-6.168c0,0,8.635,14.628,5.816,14.275s-12.513-15.156-12.513-15.156-14.275-2.644-16.566,5.816,1.41,12.689,3.525,13.218,15.685.352,15.332,2.115-9.517,4.582-14.627,3c0,0,5.639,13.923,13.041,13.218s9.693-5.288,14.275-5.64,7.931.881,5.64,2.115-10.045.4-13.746,3.55-6.7,5.085.176,6.495,19.21.529,21.677-4.759l2.467-5.286" transform="translate(-212.791 722.258)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-129">
        <path id="Контур_304" data-name="Контур 304" d="M269.614-652.83s37.009,11.984,41.239,25.378a28.116,28.116,0,0,0,3.525,12.865v2.644s-3.877-8.988-5.992-10.222c0,0,8.283-11.631-38.772-30.664" transform="translate(-269.614 652.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-131">
        <path id="Контур_307" data-name="Контур 307" d="M608.664-364.094a24.409,24.409,0,0,1,2.454,19.217l-1.468-.394s1.425-11.621-1.561-17.465.575-1.358.575-1.358" transform="translate(-606.896 365.739)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-133">
        <path id="Контур_310" data-name="Контур 310" d="M419.4-199.82a25.891,25.891,0,0,1-25.891-25.89h51.783A25.891,25.891,0,0,1,419.4-199.82" transform="translate(-393.505 225.71)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-135">
        <path id="Контур_313" data-name="Контур 313" d="M390.7-34.135h52.5A3.2,3.2,0,0,0,440-37.33H393.894a3.194,3.194,0,0,0-3.194,3.195" transform="translate(-390.7 37.33)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-137">
        <path id="Контур_316" data-name="Контур 316" d="M388.886-224.916h53.223v-.794H388.886Z" transform="translate(-388.886 225.71)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-139">
        <path id="Контур_319" data-name="Контур 319" d="M662.876-793.472H784.73a2.184,2.184,0,0,0,2.185-2.185v-1.368a2.185,2.185,0,0,0-2.185-2.185H662.876a2.185,2.185,0,0,0-2.185,2.185v1.368a2.185,2.185,0,0,0,2.185,2.185" transform="translate(-660.691 799.21)" fill="#48485d" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-140">
        <path id="Контур_318" data-name="Контур 318" d="M-224-1524.048H183.923V-1779H-224Z" transform="translate(224 1779)" fill="#48485d"/>
      </clipPath>
      <clipPath id="clip-path-141">
        <path id="Контур_322" data-name="Контур 322" d="M680.56-679.31l-.068-.009a1.456,1.456,0,0,1-1.286-1.62l12.38-95.761,6.936.2-16.636,96.229a1.19,1.19,0,0,1-1.326.962" transform="translate(-679.195 776.7)" fill="#48485d" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-143">
        <path id="Контур_325" data-name="Контур 325" d="M1514.333-679.31l.068-.009a1.456,1.456,0,0,0,1.286-1.62L1503.307-776.7l-6.937.2,16.637,96.229a1.189,1.189,0,0,0,1.326.962" transform="translate(-1496.37 776.7)" fill="#48485d" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-145">
        <path id="Контур_328" data-name="Контур 328" d="M2225.8-646.88l-1.433.231-2.71-16.882,1.432-.229,2.711,16.88" transform="translate(-2221.66 663.76)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-147">
        <path id="Контур_331" data-name="Контур 331" d="M2221.9-686.17l-.238,3.086,1.432-.229-1.193-2.857" transform="translate(-2221.66 686.17)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-149">
        <path id="Контур_334" data-name="Контур 334" d="M2320.357-635.506l-1.427-.264,3.123-16.81,1.427.265-3.123,16.809" transform="translate(-2318.93 652.58)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-151">
        <path id="Контур_337" data-name="Контур 337" d="M2344.693-674.76l-1.263,2.827,1.426.265-.163-3.093" transform="translate(-2343.43 674.76)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-153">
        <path id="Контур_340" data-name="Контур 340" d="M2306.175-674.185l-1.425-.265,3.123-16.81,1.425.265-3.123,16.81" transform="translate(-2304.75 691.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-155">
        <path id="Контур_343" data-name="Контур 343" d="M2330.512-713.43l-1.262,2.826,1.425.265-.163-3.091" transform="translate(-2329.25 713.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-157">
        <path id="Контур_346" data-name="Контур 346" d="M2263.78-591.384h11.426V-607.85H2263.78Z" transform="translate(-2263.78 607.85)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-159">
        <path id="Контур_349" data-name="Контур 349" d="M2218.97-591.384H2230.4V-607.85H2218.97Z" transform="translate(-2218.97 607.85)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-161">
        <path id="Контур_352" data-name="Контур 352" d="M2120.745-75.42c-.12.247-.388,1.579-.863,2.68h0a2.765,2.765,0,0,1-1.1,1.446c-1.193.556-3.2-.4-3.2-.4a26.533,26.533,0,0,1-3.771.238c-.334-.159-.668-5.318-.668-5.318s2.673-2.778,2.912-2.857,2.2.952,2.53.952,2.291-1.277,2.816-.957,1.529,3.815,1.337,4.212" transform="translate(-2111.15 79.684)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-163">
        <path id="Контур_355" data-name="Контур 355" d="M2136.521-78.317a9.7,9.7,0,0,1-.764,1.469c-.167.119-1.767-2.421-1.767-2.421.239-.079,2.2.952,2.531.952" transform="translate(-2133.99 79.274)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-165">
        <path id="Контур_358" data-name="Контур 358" d="M2159.118-68.76a23.307,23.307,0,0,0-2.41,2.474,13.267,13.267,0,0,0,2.737,1.666c.1-.054-.557-1.772-.525-2.064s.2-2.077.2-2.077" transform="translate(-2156.708 68.76)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-167">
        <path id="Контур_361" data-name="Контур 361" d="M2177.267-75.068c-.12.247-.388,1.579-.863,2.68h0l.291-2.523-.763-4.37c.525.323,1.529,3.815,1.337,4.212" transform="translate(-2175.93 79.28)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-169">
        <path id="Контур_364" data-name="Контур 364" d="M2115.589-51.75a26.533,26.533,0,0,1-3.771.238c-.334-.159-.668-5.318-.668-5.318s.747,2.646.764,2.778,1.073-.157,1.073-.157l2.6,2.459" transform="translate(-2111.15 56.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-171">
        <path id="Контур_367" data-name="Контур 367" d="M2118.17-56.83l.381,1.369h.788l-.585-.575-.584-.794" transform="translate(-2118.17 56.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-173">
        <path id="Контур_370" data-name="Контур 370" d="M951.9-75.42c-.12.247-.388,1.579-.863,2.68h0a2.765,2.765,0,0,1-1.1,1.446c-1.193.556-3.2-.4-3.2-.4a26.533,26.533,0,0,1-3.771.238c-.334-.159-.668-5.318-.668-5.318s2.673-2.778,2.912-2.857,2.2.952,2.53.952,2.291-1.277,2.816-.957,1.528,3.815,1.337,4.212" transform="translate(-942.3 79.684)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-175">
        <path id="Контур_373" data-name="Контур 373" d="M967.67-78.317a9.731,9.731,0,0,1-.763,1.469c-.168.119-1.767-2.421-1.767-2.421.238-.079,2.2.952,2.53.952" transform="translate(-965.14 79.274)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-177">
        <path id="Контур_376" data-name="Контур 376" d="M990.268-68.76a23.356,23.356,0,0,0-2.411,2.474,13.281,13.281,0,0,0,2.737,1.666c.1-.054-.557-1.772-.525-2.064s.2-2.077.2-2.077" transform="translate(-987.857 68.76)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-179">
        <path id="Контур_379" data-name="Контур 379" d="M1008.417-75.068c-.12.247-.388,1.579-.863,2.68h0l.291-2.523-.763-4.37c.525.323,1.528,3.815,1.337,4.212" transform="translate(-1007.08 79.28)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-181">
        <path id="Контур_382" data-name="Контур 382" d="M946.739-51.75a26.533,26.533,0,0,1-3.771.238c-.334-.159-.668-5.318-.668-5.318s.747,2.646.764,2.778,1.073-.157,1.073-.157l2.6,2.459" transform="translate(-942.3 56.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-183">
        <path id="Контур_385" data-name="Контур 385" d="M949.32-56.83l.381,1.369h.788l-.585-.575-.584-.794" transform="translate(-949.32 56.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-185">
        <path id="Контур_388" data-name="Контур 388" d="M2203.54-542.568c-.107.259-.3,1.625-.726,2.77h0a2.808,2.808,0,0,1-1.03,1.53c-1.18.631-3.27-.223-3.27-.223a27.031,27.031,0,0,1-3.817.454c-.348-.143-.978-5.363-.978-5.363s2.559-2.97,2.8-3.064,2.283.844,2.622.825,2.256-1.426,2.807-1.131,1.766,3.787,1.594,4.2" transform="translate(-2193.72 546.81)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-187">
        <path id="Контур_391" data-name="Контур 391" d="M2218.282-543.311a9.866,9.866,0,0,1-.692,1.534c-.163.13-1.93-2.358-1.93-2.358.238-.094,2.283.844,2.622.825" transform="translate(-2215.66 544.142)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-189">
        <path id="Контур_394" data-name="Контур 394" d="M2242.714-535.79a23.735,23.735,0,0,0-2.309,2.648,13.524,13.524,0,0,0,2.874,1.539c.094-.06-.665-1.769-.649-2.066s.084-2.12.084-2.12" transform="translate(-2240.405 535.79)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-191">
        <path id="Контур_397" data-name="Контур 397" d="M2259.843-542.288c-.107.259-.3,1.625-.725,2.77h0l.154-2.578-1.021-4.4c.552.3,1.766,3.787,1.593,4.2" transform="translate(-2258.25 546.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-193">
        <path id="Контур_400" data-name="Контур 400" d="M2198.514-515.141a27.03,27.03,0,0,1-3.817.454c-.348-.143-.978-5.363-.978-5.363s.908,2.645.932,2.778,1.083-.219,1.083-.219l2.78,2.351" transform="translate(-2193.72 520.05)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-195">
        <path id="Контур_403" data-name="Контур 403" d="M2200.85-520.44l.465,1.368.8-.045-.626-.551-.639-.773" transform="translate(-2200.85 520.44)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-197">
        <path id="Контур_406" data-name="Контур 406" d="M2064.787-523.683c-.083.176-.237,1.1-.563,1.88h0a1.976,1.976,0,0,1-.8,1.047,4.264,4.264,0,0,1-2.537-.113,24.053,24.053,0,0,1-2.961.351c-.27-.092-.756-3.614-.756-3.614s1.986-2.037,2.171-2.1,1.771.544,2.033.528,1.75-.99,2.177-.8a5.846,5.846,0,0,1,1.235,2.821" transform="translate(-2057.17 526.53)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-199">
        <path id="Контур_409" data-name="Контур 409" d="M2076.233-523.727a6.406,6.406,0,0,1-.538,1.044c-.126.091-1.5-1.572-1.5-1.572.183-.066,1.771.544,2.033.528" transform="translate(-2074.2 524.259)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-201">
        <path id="Контур_412" data-name="Контур 412" d="M2095.169-519.1a17.518,17.518,0,0,0-1.792,1.815,10.779,10.779,0,0,0,2.228,1.007c.074-.041-.515-1.188-.5-1.388s.066-1.434.066-1.434" transform="translate(-2093.376 519.1)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-203">
        <path id="Контур_415" data-name="Контур 415" d="M2108.465-523.509c-.083.176-.237,1.1-.563,1.88h0l.121-1.744-.792-2.959a5.848,5.848,0,0,1,1.235,2.821" transform="translate(-2107.23 526.33)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-205">
        <path id="Контур_418" data-name="Контур 418" d="M2060.887-504.457a24.041,24.041,0,0,1-2.961.351c-.27-.092-.756-3.614-.756-3.614s.7,1.778.721,1.868.84-.161.84-.161l2.156,1.556" transform="translate(-2057.17 507.72)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-207">
        <path id="Контур_421" data-name="Контур 421" d="M2062.7-508.07l.36.92.621-.04-.486-.365-.495-.516" transform="translate(-2062.7 508.07)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-209">
        <path id="Контур_424" data-name="Контур 424" d="M1193.731-832.736s-6.945-2-8.878-1.444c-1.364.389-3.105,2.406-4.013,3.56a.511.511,0,0,0,.408.825,10.6,10.6,0,0,0,4.026-.6c.928-.488,2.31.586,3.849.593,1.182.005,2.7,0,2.7,0l1.907-2.927" transform="translate(-1180.731 834.276)" fill="none" clip-rule="evenodd"/>
      </clipPath>
    </defs>
    <g id="office-woman" transform="translate(-0.574 1569.903)">
      <g id="Сгруппировать_246" data-name="Сгруппировать 246" transform="translate(0.574 -1569.903)" clip-path="url(#clip-path)">
        <g id="Сгруппировать_245" data-name="Сгруппировать 245" transform="translate(-28.628 -26.655)" clip-path="url(#clip-path-2)">
          <path id="Контур_110" data-name="Контур 110" d="M-4.426-1574.9H335.263v201H-4.426Z" transform="translate(32.416 1600.92)" fill="#ebf3f9"/>
        </g>
      </g>
      <g id="Сгруппировать_248" data-name="Сгруппировать 248" transform="translate(189.031 -1430.639)" clip-path="url(#clip-path-3)">
        <g id="Сгруппировать_247" data-name="Сгруппировать 247" transform="translate(-217.085 -165.919)" clip-path="url(#clip-path-2)">
          <path id="Контур_113" data-name="Контур 113" d="M1473.948-482.43h12.216v61.149h-12.216Z" transform="translate(-1257.5 647.711)" fill="#acc6eb"/>
        </g>
      </g>
      <g id="Сгруппировать_250" data-name="Сгруппировать 250" transform="translate(237.484 -1430.639)" clip-path="url(#clip-path-5)">
        <g id="Сгруппировать_249" data-name="Сгруппировать 249" transform="translate(-265.537 -165.919)" clip-path="url(#clip-path-2)">
          <path id="Контур_116" data-name="Контур 116" d="M1854.04-482.43h12.215v61.149H1854.04Z" transform="translate(-1589.14 647.711)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_252" data-name="Сгруппировать 252" transform="translate(147.836 -1430.639)" clip-path="url(#clip-path-7)">
        <g id="Сгруппировать_251" data-name="Сгруппировать 251" transform="translate(-175.89 -165.919)" clip-path="url(#clip-path-2)">
          <path id="Контур_119" data-name="Контур 119" d="M1150.786-482.43H1163v61.149h-12.217Z" transform="translate(-975.534 647.711)" fill="#b9d3f4"/>
        </g>
      </g>
      <g id="Сгруппировать_254" data-name="Сгруппировать 254" transform="translate(193.636 -1503.409)" clip-path="url(#clip-path-9)">
        <g id="Сгруппировать_253" data-name="Сгруппировать 253" transform="translate(-221.69 -93.148)" clip-path="url(#clip-path-2)">
          <path id="Контур_122" data-name="Контур 122" d="M1510.07-1053.288h63.3v65.562h-63.3Z" transform="translate(-1289.018 1145.799)" fill="#b9d3f4"/>
        </g>
      </g>
      <g id="Сгруппировать_256" data-name="Сгруппировать 256" transform="translate(187.366 -1503.409)" clip-path="url(#clip-path-11)">
        <g id="Сгруппировать_255" data-name="Сгруппировать 255" transform="translate(-215.419 -93.148)" clip-path="url(#clip-path-2)">
          <path id="Контур_125" data-name="Контур 125" d="M1460.88-1053.288h63.3v65.562h-63.3Z" transform="translate(-1246.098 1145.799)" fill="#acc6eb"/>
        </g>
      </g>
      <g id="Сгруппировать_258" data-name="Сгруппировать 258" transform="translate(160.236 -1439.122)" clip-path="url(#clip-path-13)">
        <g id="Сгруппировать_257" data-name="Сгруппировать 257" transform="translate(-188.29 -157.435)" clip-path="url(#clip-path-2)">
          <path id="Контур_128" data-name="Контур 128" d="M1248.06-548.98H1334.2v10.611H1248.06Z" transform="translate(-1060.408 705.778)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_260" data-name="Сгруппировать 260" transform="translate(152.354 -1439.122)" clip-path="url(#clip-path-15)">
        <g id="Сгруппировать_259" data-name="Сгруппировать 259" transform="translate(-180.408 -157.435)" clip-path="url(#clip-path-2)">
          <path id="Контур_131" data-name="Контур 131" d="M1186.23-548.98h56.347v10.611H1186.23Z" transform="translate(-1006.46 705.778)" fill="#acc6eb"/>
        </g>
      </g>
      <g id="Сгруппировать_262" data-name="Сгруппировать 262" transform="translate(2.467 -1371.371)" clip-path="url(#clip-path-17)">
        <g id="Сгруппировать_261" data-name="Сгруппировать 261" transform="translate(-30.52 -225.186)" clip-path="url(#clip-path-2)">
          <path id="Контур_134" data-name="Контур 134" d="M10.42-17.5H363.3v2.743H10.42Z" transform="translate(19.463 242.049)" fill="#9abee1"/>
        </g>
      </g>
      <g id="Сгруппировать_264" data-name="Сгруппировать 264" transform="translate(161.174 -1536.176)" clip-path="url(#clip-path-19)">
        <g id="Сгруппировать_263" data-name="Сгруппировать 263" transform="translate(-189.227 -60.382)" clip-path="url(#clip-path-2)">
          <path id="Контур_137" data-name="Контур 137" d="M1255.415-1310.328h48.669V-1259h-48.669Z" transform="translate(-1066.825 1370.073)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_266" data-name="Сгруппировать 266" transform="translate(163.386 -1509.299)" clip-path="url(#clip-path-21)">
        <g id="Сгруппировать_265" data-name="Сгруппировать 265" transform="translate(-191.439 -87.258)" clip-path="url(#clip-path-2)">
          <path id="Контур_140" data-name="Контур 140" d="M1272.765-1099.492h27.929v37.434h-27.929Z" transform="translate(-1081.964 1186.113)" fill="#e4925a"/>
        </g>
      </g>
      <g id="Сгруппировать_268" data-name="Сгруппировать 268" transform="translate(148.871 -1460.723)" clip-path="url(#clip-path-23)">
        <g id="Сгруппировать_267" data-name="Сгруппировать 267" transform="translate(-176.924 -135.834)" clip-path="url(#clip-path-2)">
          <path id="Контур_143" data-name="Контур 143" d="M1158.905-718.431h52.212v64.785h-52.212Z" transform="translate(-982.618 853.628)" fill="#353349"/>
        </g>
      </g>
      <g id="Сгруппировать_270" data-name="Сгруппировать 270" transform="translate(157.84 -1405.24)" clip-path="url(#clip-path-25)">
        <g id="Сгруппировать_269" data-name="Сгруппировать 269" transform="translate(-185.893 -191.317)" clip-path="url(#clip-path-2)">
          <path id="Контур_146" data-name="Контур 146" d="M1229.26-283.19h13.163v30.049H1229.26Z" transform="translate(-1044.004 473.87)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_272" data-name="Сгруппировать 272" transform="translate(135.974 -1391.46)" clip-path="url(#clip-path-27)">
        <g id="Сгруппировать_271" data-name="Сгруппировать 271" transform="translate(-164.027 -205.097)" clip-path="url(#clip-path-2)">
          <path id="Контур_149" data-name="Контур 149" d="M1057.731-175.09h22.926v21.364h-22.926Z" transform="translate(-894.341 379.55)" fill="#2a4e95"/>
        </g>
      </g>
      <g id="Сгруппировать_274" data-name="Сгруппировать 274" transform="translate(149.131 -1462.171)" clip-path="url(#clip-path-29)">
        <g id="Сгруппировать_273" data-name="Сгруппировать 273" transform="translate(-177.184 -134.386)" clip-path="url(#clip-path-2)">
          <path id="Контур_152" data-name="Контур 152" d="M1160.94-729.79h75.274v76.967H1160.94Z" transform="translate(-984.393 863.539)" fill="#3e3d55"/>
        </g>
      </g>
      <g id="Сгруппировать_276" data-name="Сгруппировать 276" transform="translate(172.841 -1506.965)" clip-path="url(#clip-path-31)">
        <g id="Сгруппировать_275" data-name="Сгруппировать 275" transform="translate(-200.894 -89.593)" clip-path="url(#clip-path-2)">
          <path id="Контур_155" data-name="Контур 155" d="M1346.94-1081.18h53.083v62.164H1346.94Z" transform="translate(-1146.683 1170.135)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_278" data-name="Сгруппировать 278" transform="translate(183.186 -1492.939)" clip-path="url(#clip-path-33)">
        <g id="Сгруппировать_277" data-name="Сгруппировать 277" transform="translate(-211.239 -103.619)" clip-path="url(#clip-path-2)">
          <path id="Контур_158" data-name="Контур 158" d="M1428.09-971.151h21.8v24.172h-21.8Z" transform="translate(-1217.488 1074.132)" fill="#ebf3f9"/>
        </g>
      </g>
      <g id="Сгруппировать_280" data-name="Сгруппировать 280" transform="translate(170.651 -1516.893)" clip-path="url(#clip-path-35)">
        <g id="Сгруппировать_279" data-name="Сгруппировать 279" transform="translate(-198.705 -79.664)" clip-path="url(#clip-path-2)">
          <path id="Контур_161" data-name="Контур 161" d="M1329.763-1159.064h8.271v11.076h-8.271Z" transform="translate(-1131.696 1238.091)" fill="#e4925a"/>
        </g>
      </g>
      <g id="Сгруппировать_282" data-name="Сгруппировать 282" transform="translate(179.006 -1515.914)" clip-path="url(#clip-path-37)">
        <g id="Сгруппировать_281" data-name="Сгруппировать 281" transform="translate(-207.059 -80.644)" clip-path="url(#clip-path-2)">
          <path id="Контур_164" data-name="Контур 164" d="M1395.3-1151.38h15.177v19.7H1395.3Z" transform="translate(-1188.878 1231.387)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_284" data-name="Сгруппировать 284" transform="translate(179.006 -1515.677)" clip-path="url(#clip-path-39)">
        <g id="Сгруппировать_283" data-name="Сгруппировать 283" transform="translate(-207.059 -80.88)" clip-path="url(#clip-path-2)">
          <path id="Контур_167" data-name="Контур 167" d="M1395.3-1149.525h9.635v10.588H1395.3Z" transform="translate(-1188.878 1229.768)" fill="#f4a169"/>
        </g>
      </g>
      <g id="Сгруппировать_286" data-name="Сгруппировать 286" transform="translate(172.407 -1529.508)" clip-path="url(#clip-path-41)">
        <g id="Сгруппировать_285" data-name="Сгруппировать 285" transform="translate(-200.46 -67.049)" clip-path="url(#clip-path-2)">
          <path id="Контур_170" data-name="Контур 170" d="M1343.536-1258.026H1361.7v21.082h-18.162Z" transform="translate(-1143.713 1324.438)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_288" data-name="Сгруппировать 288" transform="translate(178.971 -1530.969)" clip-path="url(#clip-path-43)">
        <g id="Сгруппировать_287" data-name="Сгруппировать 287" transform="translate(-207.025 -65.588)" clip-path="url(#clip-path-2)">
          <path id="Контур_173" data-name="Контур 173" d="M1395.03-1269.486h13.849v19.528H1395.03Z" transform="translate(-1188.643 1334.437)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_290" data-name="Сгруппировать 290" transform="translate(172.039 -1530.992)" clip-path="url(#clip-path-45)">
        <g id="Сгруппировать_289" data-name="Сгруппировать 289" transform="translate(-200.093 -65.565)" clip-path="url(#clip-path-2)">
          <path id="Контур_176" data-name="Контур 176" d="M1340.651-1269.665h8.943V-1254h-8.943Z" transform="translate(-1141.196 1334.593)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_292" data-name="Сгруппировать 292" transform="translate(183.373 -1503.745)" clip-path="url(#clip-path-47)">
        <g id="Сгруппировать_291" data-name="Сгруппировать 291" transform="translate(-211.426 -92.813)" clip-path="url(#clip-path-2)">
          <path id="Контур_179" data-name="Контур 179" d="M1429.558-1055.92h22.618v32.888h-22.618Z" transform="translate(-1218.769 1148.095)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_294" data-name="Сгруппировать 294" transform="translate(162.109 -1481.08)" clip-path="url(#clip-path-49)">
        <g id="Сгруппировать_293" data-name="Сгруппировать 293" transform="translate(-190.162 -115.478)" clip-path="url(#clip-path-2)">
          <path id="Контур_182" data-name="Контур 182" d="M1262.75-878.121h33.157V-867.1H1262.75Z" transform="translate(-1073.225 992.961)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_296" data-name="Сгруппировать 296" transform="translate(188.977 -1503.745)" clip-path="url(#clip-path-51)">
        <g id="Сгруппировать_295" data-name="Сгруппировать 295" transform="translate(-217.03 -92.813)" clip-path="url(#clip-path-2)">
          <path id="Контур_185" data-name="Контур 185" d="M1473.52-1055.92h17.014v20.021H1473.52Z" transform="translate(-1257.127 1148.095)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_298" data-name="Сгруппировать 298" transform="translate(123.755 -1473.525)" clip-path="url(#clip-path-53)">
        <g id="Сгруппировать_297" data-name="Сгруппировать 297" transform="translate(-151.809 -123.033)" clip-path="url(#clip-path-54)">
          <path id="Контур_188" data-name="Контур 188" d="M961.88-818.855h24.693v3.142H961.88Z" transform="translate(-810.709 941.25)" fill="#36364b"/>
        </g>
      </g>
      <g id="Сгруппировать_300" data-name="Сгруппировать 300" transform="translate(87.615 -1497.163)" clip-path="url(#clip-path-55)">
        <g id="Сгруппировать_299" data-name="Сгруппировать 299" transform="translate(-115.669 -99.394)" clip-path="url(#clip-path-56)">
          <path id="Контур_191" data-name="Контур 191" d="M678.377-1004.29h46.4v26.78h-46.4Z" transform="translate(-563.346 1103.047)" fill="#262636"/>
        </g>
      </g>
      <g id="Сгруппировать_302" data-name="Сгруппировать 302" transform="translate(87.082 -1497.164)" clip-path="url(#clip-path-57)">
        <g id="Сгруппировать_301" data-name="Сгруппировать 301" transform="translate(-115.136 -99.394)" clip-path="url(#clip-path-54)">
          <path id="Контур_194" data-name="Контур 194" d="M674.195-1004.294h46.4v26.781h-46.4Z" transform="translate(-559.697 1103.05)" fill="#36364b"/>
        </g>
      </g>
      <g id="Сгруппировать_304" data-name="Сгруппировать 304" transform="translate(106.556 -1486.411)" clip-path="url(#clip-path-59)">
        <g id="Сгруппировать_303" data-name="Сгруппировать 303" transform="translate(-134.61 -110.147)" clip-path="url(#clip-path-2)">
          <path id="Контур_197" data-name="Контур 197" d="M826.961-919.942h4.9v4.8h-4.9Z" transform="translate(-692.989 1029.451)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_306" data-name="Сгруппировать 306" transform="translate(258.947 -1430.798)" clip-path="url(#clip-path-61)">
        <g id="Сгруппировать_305" data-name="Сгруппировать 305" transform="translate(-287.001 -165.759)" clip-path="url(#clip-path-2)">
          <path id="Контур_200" data-name="Контур 200" d="M2022.41-483.68H2078.6v4.648H2022.41Z" transform="translate(-1736.047 648.802)" fill="#9abee1"/>
        </g>
      </g>
      <g id="Сгруппировать_308" data-name="Сгруппировать 308" transform="translate(258.947 -1430.798)" clip-path="url(#clip-path-63)">
        <g id="Сгруппировать_307" data-name="Сгруппировать 307" transform="translate(-287.001 -165.759)" clip-path="url(#clip-path-2)">
          <path id="Контур_203" data-name="Контур 203" d="M2022.41-483.68h44.964v4.648H2022.41Z" transform="translate(-1736.047 648.802)" fill="#b9d3f4"/>
        </g>
      </g>
      <g id="Сгруппировать_310" data-name="Сгруппировать 310" transform="translate(272.657 -1427.425)" clip-path="url(#clip-path-65)">
        <g id="Сгруппировать_309" data-name="Сгруппировать 309" transform="translate(-300.711 -169.133)" clip-path="url(#clip-path-2)">
          <path id="Контур_206" data-name="Контур 206" d="M2129.96-457.22h39.732v57.329H2129.96Z" transform="translate(-1829.887 625.715)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_312" data-name="Сгруппировать 312" transform="translate(261.88 -1427.425)" clip-path="url(#clip-path-67)">
        <g id="Сгруппировать_311" data-name="Сгруппировать 311" transform="translate(-289.934 -169.133)" clip-path="url(#clip-path-2)">
          <path id="Контур_209" data-name="Контур 209" d="M2045.42-457.22h39.732v57.329H2045.42Z" transform="translate(-1756.124 625.715)" fill="#dfebfc"/>
        </g>
      </g>
      <g id="Сгруппировать_314" data-name="Сгруппировать 314" transform="translate(263.946 -1425.84)" clip-path="url(#clip-path-69)">
        <g id="Сгруппировать_313" data-name="Сгруппировать 313" transform="translate(-291.999 -170.717)" clip-path="url(#clip-path-2)">
          <path id="Контур_212" data-name="Контур 212" d="M2061.62-444.79h35.6v52.649h-35.6Z" transform="translate(-1770.259 614.87)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_316" data-name="Сгруппировать 316" transform="translate(264.132 -1408.845)" clip-path="url(#clip-path-71)">
        <g id="Сгруппировать_315" data-name="Сгруппировать 315" transform="translate(-292.185 -187.712)" clip-path="url(#clip-path-2)">
          <path id="Контур_215" data-name="Контур 215" d="M2063.08-311.47h35.228v1.644H2063.08Z" transform="translate(-1771.532 498.545)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_318" data-name="Сгруппировать 318" transform="translate(264.132 -1391.519)" clip-path="url(#clip-path-73)">
        <g id="Сгруппировать_317" data-name="Сгруппировать 317" transform="translate(-292.185 -205.039)" clip-path="url(#clip-path-2)">
          <path id="Контур_218" data-name="Контур 218" d="M2063.08-175.55h35.228v1.644H2063.08Z" transform="translate(-1771.532 379.951)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_320" data-name="Сгруппировать 320" transform="translate(277.073 -1423.64)" clip-path="url(#clip-path-75)">
        <g id="Сгруппировать_319" data-name="Сгруппировать 319" transform="translate(-305.126 -172.917)" clip-path="url(#clip-path-2)">
          <path id="Контур_221" data-name="Контур 221" d="M2164.6-427.53h8.688v2.388H2164.6Z" transform="translate(-1860.111 599.81)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_322" data-name="Сгруппировать 322" transform="translate(277.073 -1406.696)" clip-path="url(#clip-path-77)">
        <g id="Сгруппировать_321" data-name="Сгруппировать 321" transform="translate(-305.126 -189.861)" clip-path="url(#clip-path-2)">
          <path id="Контур_224" data-name="Контур 224" d="M2164.6-294.61h8.688v2.386H2164.6Z" transform="translate(-1860.111 483.834)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_324" data-name="Сгруппировать 324" transform="translate(277.073 -1389.034)" clip-path="url(#clip-path-79)">
        <g id="Сгруппировать_323" data-name="Сгруппировать 323" transform="translate(-305.126 -207.523)" clip-path="url(#clip-path-2)">
          <path id="Контур_227" data-name="Контур 227" d="M2164.6-156.06h8.688v2.388H2164.6Z" transform="translate(-1860.111 362.946)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_326" data-name="Сгруппировать 326" transform="translate(226.986 -1550.827)" clip-path="url(#clip-path-81)">
        <g id="Сгруппировать_325" data-name="Сгруппировать 325" transform="translate(-255.04 -45.73)" clip-path="url(#clip-path-2)">
          <path id="Контур_230" data-name="Контур 230" d="M1771.69-1425.264h26.95v26.183h-26.95Z" transform="translate(-1517.287 1470.357)" fill="#c0d7fa"/>
        </g>
      </g>
      <g id="Сгруппировать_328" data-name="Сгруппировать 328" transform="translate(228.099 -1549.748)" clip-path="url(#clip-path-83)">
        <g id="Сгруппировать_327" data-name="Сгруппировать 327" transform="translate(-256.153 -46.809)" clip-path="url(#clip-path-2)">
          <path id="Контур_233" data-name="Контур 233" d="M1780.42-1416.8h24.724v24.025H1780.42Z" transform="translate(-1524.905 1462.972)" fill="#dfebfc"/>
        </g>
      </g>
      <g id="Сгруппировать_330" data-name="Сгруппировать 330" transform="translate(239.292 -1548.007)" clip-path="url(#clip-path-85)">
        <g id="Сгруппировать_329" data-name="Сгруппировать 329" transform="translate(-267.345 -48.551)" clip-path="url(#clip-path-2)">
          <path id="Контур_236" data-name="Контур 236" d="M1868.22-1403.139h2.339v2.338h-2.339Z" transform="translate(-1601.512 1451.052)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_332" data-name="Сгруппировать 332" transform="translate(243.517 -1546.997)" clip-path="url(#clip-path-87)">
        <g id="Сгруппировать_331" data-name="Сгруппировать 331" transform="translate(-271.57 -49.561)" clip-path="url(#clip-path-2)">
          <path id="Контур_239" data-name="Контур 239" d="M1901.362-1395.216h2.338v2.339h-2.338Z" transform="translate(-1630.43 1444.139)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_334" data-name="Сгруппировать 334" transform="translate(247.016 -1543.887)" clip-path="url(#clip-path-89)">
        <g id="Сгруппировать_333" data-name="Сгруппировать 333" transform="translate(-275.069 -52.67)" clip-path="url(#clip-path-2)">
          <path id="Контур_242" data-name="Контур 242" d="M1928.814-1370.824h2.339v2.339h-2.339Z" transform="translate(-1654.382 1422.856)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_336" data-name="Сгруппировать 336" transform="translate(248.549 -1539.281)" clip-path="url(#clip-path-91)">
        <g id="Сгруппировать_335" data-name="Сгруппировать 335" transform="translate(-276.602 -57.276)" clip-path="url(#clip-path-2)">
          <path id="Контур_245" data-name="Контур 245" d="M1940.84-1334.692h2.339v2.338h-2.339Z" transform="translate(-1664.875 1391.33)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_338" data-name="Сгруппировать 338" transform="translate(247.243 -1534.191)" clip-path="url(#clip-path-93)">
        <g id="Сгруппировать_337" data-name="Сгруппировать 337" transform="translate(-275.297 -62.367)" clip-path="url(#clip-path-2)">
          <path id="Контур_248" data-name="Контур 248" d="M1930.6-1294.756h2.339v2.338H1930.6Z" transform="translate(-1655.939 1356.485)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_340" data-name="Сгруппировать 340" transform="translate(243.903 -1530.842)" clip-path="url(#clip-path-95)">
        <g id="Сгруппировать_339" data-name="Сгруппировать 339" transform="translate(-271.957 -65.715)" clip-path="url(#clip-path-2)">
          <path id="Контур_251" data-name="Контур 251" d="M1904.4-1268.49h2.338v2.338H1904.4Z" transform="translate(-1633.078 1333.568)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_342" data-name="Сгруппировать 342" transform="translate(239.292 -1529.493)" clip-path="url(#clip-path-97)">
        <g id="Сгруппировать_341" data-name="Сгруппировать 341" transform="translate(-267.345 -67.065)" clip-path="url(#clip-path-2)">
          <path id="Контур_254" data-name="Контур 254" d="M1868.22-1257.9h2.339v2.338h-2.339Z" transform="translate(-1601.512 1324.329)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_344" data-name="Сгруппировать 344" transform="translate(235.051 -1546.997)" clip-path="url(#clip-path-99)">
        <g id="Сгруппировать_343" data-name="Сгруппировать 343" transform="translate(-263.104 -49.561)" clip-path="url(#clip-path-2)">
          <path id="Контур_257" data-name="Контур 257" d="M1834.952-1395.216h2.339v2.339h-2.339Z" transform="translate(-1572.485 1444.139)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_346" data-name="Сгруппировать 346" transform="translate(231.551 -1543.887)" clip-path="url(#clip-path-101)">
        <g id="Сгруппировать_345" data-name="Сгруппировать 345" transform="translate(-259.604 -52.67)" clip-path="url(#clip-path-2)">
          <path id="Контур_260" data-name="Контур 260" d="M1807.494-1370.824h2.339v2.339h-2.339Z" transform="translate(-1548.527 1422.856)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(230.018 -1539.281)" clip-path="url(#clip-path-103)">
        <g id="Сгруппировать_347" data-name="Сгруппировать 347" transform="translate(-258.071 -57.276)" clip-path="url(#clip-path-2)">
          <path id="Контур_263" data-name="Контур 263" d="M1795.47-1334.692h2.338v2.338h-2.338Z" transform="translate(-1538.036 1391.33)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_350" data-name="Сгруппировать 350" transform="translate(231.324 -1534.191)" clip-path="url(#clip-path-105)">
        <g id="Сгруппировать_349" data-name="Сгруппировать 349" transform="translate(-259.378 -62.367)" clip-path="url(#clip-path-2)">
          <path id="Контур_266" data-name="Контур 266" d="M1805.717-1294.756h2.339v2.338h-2.339Z" transform="translate(-1546.977 1356.485)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_352" data-name="Сгруппировать 352" transform="translate(234.664 -1530.842)" clip-path="url(#clip-path-107)">
        <g id="Сгруппировать_351" data-name="Сгруппировать 351" transform="translate(-262.717 -65.715)" clip-path="url(#clip-path-2)">
          <path id="Контур_269" data-name="Контур 269" d="M1831.915-1268.49h2.338v2.338h-2.338Z" transform="translate(-1569.835 1333.568)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_354" data-name="Сгруппировать 354" transform="translate(239.681 -1539.281)" clip-path="url(#clip-path-109)">
        <g id="Сгруппировать_353" data-name="Сгруппировать 353" transform="translate(-267.734 -57.276)" clip-path="url(#clip-path-2)">
          <path id="Контур_272" data-name="Контур 272" d="M1871.27-1334.692h2.338v2.338h-2.338Z" transform="translate(-1604.174 1391.33)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_356" data-name="Сгруппировать 356" transform="translate(240.139 -1545.394)" clip-path="url(#clip-path-111)">
        <g id="Сгруппировать_355" data-name="Сгруппировать 355" transform="translate(-268.193 -51.164)" clip-path="url(#clip-path-2)">
          <path id="Контур_275" data-name="Контур 275" d="M1874.87-1382.639h1.42v7.919h-1.42Z" transform="translate(-1607.315 1433.166)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_358" data-name="Сгруппировать 358" transform="translate(240.011 -1541.695)" clip-path="url(#clip-path-113)">
        <g id="Сгруппировать_357" data-name="Сгруппировать 357" transform="translate(-268.064 -54.862)" clip-path="url(#clip-path-2)">
          <path id="Контур_278" data-name="Контур 278" d="M1873.86-1353.628h5.066v4.382h-5.066Z" transform="translate(-1606.433 1407.853)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_360" data-name="Сгруппировать 360" transform="translate(35.166 -1425.877)" clip-path="url(#clip-path-115)">
        <g id="Сгруппировать_359" data-name="Сгруппировать 359" transform="translate(-63.219 -170.681)" clip-path="url(#clip-path-2)">
          <path id="Контур_281" data-name="Контур 281" d="M266.931-445.075h39.052v28.009H266.931Z" transform="translate(-204.349 615.119)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_362" data-name="Сгруппировать 362" transform="translate(39.681 -1416.821)" clip-path="url(#clip-path-117)">
        <g id="Сгруппировать_361" data-name="Сгруппировать 361" transform="translate(-67.734 -179.736)" clip-path="url(#clip-path-2)">
          <path id="Контур_284" data-name="Контур 284" d="M302.349-374.038H339.5v16H302.349Z" transform="translate(-235.252 553.137)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_364" data-name="Сгруппировать 364" transform="translate(73.569 -1404.308)" clip-path="url(#clip-path-119)">
        <g id="Сгруппировать_363" data-name="Сгруппировать 363" transform="translate(-101.622 -192.25)" clip-path="url(#clip-path-2)">
          <path id="Контур_287" data-name="Контур 287" d="M568.187-275.876h8.383v12.982h-8.383Z" transform="translate(-467.203 467.488)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_366" data-name="Сгруппировать 366" transform="translate(85.47 -1445.842)" clip-path="url(#clip-path-121)">
        <g id="Сгруппировать_365" data-name="Сгруппировать 365" transform="translate(-113.523 -150.715)" clip-path="url(#clip-path-2)">
          <path id="Контур_290" data-name="Контур 290" d="M661.546-601.7h44.646v35.316H661.546Z" transform="translate(-548.66 751.775)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_368" data-name="Сгруппировать 368" transform="translate(89.388 -1439.128)" clip-path="url(#clip-path-123)">
        <g id="Сгруппировать_367" data-name="Сгруппировать 367" transform="translate(-117.441 -157.429)" clip-path="url(#clip-path-2)">
          <path id="Контур_293" data-name="Контур 293" d="M692.282-549.03h35.233v32.292H692.282Z" transform="translate(-575.478 705.822)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_370" data-name="Сгруппировать 370" transform="translate(86.766 -1411.365)" clip-path="url(#clip-path-125)">
        <g id="Сгруппировать_369" data-name="Сгруппировать 369" transform="translate(-114.819 -185.193)" clip-path="url(#clip-path-2)">
          <path id="Контур_296" data-name="Контур 296" d="M671.713-331.232h5.238v17.1h-5.238Z" transform="translate(-557.531 515.787)" fill="#c7dbf4"/>
        </g>
      </g>
      <g id="Сгруппировать_372" data-name="Сгруппировать 372" transform="translate(27.627 -1461.848)" clip-path="url(#clip-path-127)">
        <g id="Сгруппировать_371" data-name="Сгруппировать 371" transform="translate(-55.68 -134.709)" clip-path="url(#clip-path-2)">
          <path id="Контур_299" data-name="Контур 299" d="M207.791-727.258h58.447v46.148H207.791Z" transform="translate(-152.748 861.33)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_374" data-name="Сгруппировать 374" transform="translate(34.871 -1452.998)" clip-path="url(#clip-path-129)">
        <g id="Сгруппировать_373" data-name="Сгруппировать 373" transform="translate(-62.924 -143.56)" clip-path="url(#clip-path-2)">
          <path id="Контур_302" data-name="Контур 302" d="M264.614-657.83h46.038v42.161H264.614Z" transform="translate(-202.327 800.752)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_376" data-name="Сгруппировать 376" transform="translate(77.866 -1416.401)" clip-path="url(#clip-path-131)">
        <g id="Сгруппировать_375" data-name="Сгруппировать 375" transform="translate(-105.919 -180.157)" clip-path="url(#clip-path-2)">
          <path id="Контур_305" data-name="Контур 305" d="M601.9-370.739h6.5V-348.6h-6.5Z" transform="translate(-496.614 550.259)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_378" data-name="Сгруппировать 378" transform="translate(50.664 -1398.55)" clip-path="url(#clip-path-133)">
        <g id="Сгруппировать_377" data-name="Сгруппировать 377" transform="translate(-78.717 -198.007)" clip-path="url(#clip-path-2)">
          <path id="Контур_308" data-name="Контур 308" d="M388.505-230.71h53.057v27.165H388.505Z" transform="translate(-310.425 428.08)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_380" data-name="Сгруппировать 380" transform="translate(50.306 -1374.536)" clip-path="url(#clip-path-135)">
        <g id="Сгруппировать_379" data-name="Сгруппировать 379" transform="translate(-78.359 -222.021)" clip-path="url(#clip-path-2)">
          <path id="Контур_311" data-name="Контур 311" d="M385.7-42.33h53.772v4.469H385.7Z" transform="translate(-307.978 263.714)" fill="#d3e3f8"/>
        </g>
      </g>
      <g id="Сгруппировать_382" data-name="Сгруппировать 382" transform="translate(50.075 -1398.55)" clip-path="url(#clip-path-137)">
        <g id="Сгруппировать_381" data-name="Сгруппировать 381" transform="translate(-78.128 -198.007)" clip-path="url(#clip-path-2)">
          <path id="Контур_314" data-name="Контур 314" d="M383.886-230.71h54.5v2.069h-54.5Z" transform="translate(-306.395 428.08)" fill="#9fc1f4"/>
        </g>
      </g>
      <g id="Сгруппировать_384" data-name="Сгруппировать 384" transform="translate(84.723 -1471.658)" clip-path="url(#clip-path-139)">
        <g id="Сгруппировать_383" data-name="Сгруппировать 383" transform="translate(-112.777 -124.9)" clip-path="url(#clip-path-140)">
          <path id="Контур_317" data-name="Контур 317" d="M655.691-804.21h127.5v7.012h-127.5Z" transform="translate(-543.552 928.472)" fill="#48485d"/>
        </g>
      </g>
      <g id="Сгруппировать_386" data-name="Сгруппировать 386" transform="translate(87.082 -1468.788)" clip-path="url(#clip-path-141)">
        <g id="Сгруппировать_385" data-name="Сгруппировать 385" transform="translate(-115.136 -127.769)" clip-path="url(#clip-path-140)">
          <path id="Контур_320" data-name="Контур 320" d="M674.195-781.7h20.6v98.674h-20.6Z" transform="translate(-559.697 908.832)" fill="#48485d"/>
        </g>
      </g>
      <g id="Сгруппировать_388" data-name="Сгруппировать 388" transform="translate(191.252 -1468.788)" clip-path="url(#clip-path-143)">
        <g id="Сгруппировать_387" data-name="Сгруппировать 387" transform="translate(-219.306 -127.769)" clip-path="url(#clip-path-140)">
          <path id="Контур_323" data-name="Контур 323" d="M1491.37-781.7h20.6v98.674h-20.6Z" transform="translate(-1272.702 908.832)" fill="#48485d"/>
        </g>
      </g>
      <g id="Сгруппировать_390" data-name="Сгруппировать 390" transform="translate(283.709 -1454.391)" clip-path="url(#clip-path-145)">
        <g id="Сгруппировать_389" data-name="Сгруппировать 389" transform="translate(-311.763 -142.166)" clip-path="url(#clip-path-2)">
          <path id="Контур_326" data-name="Контур 326" d="M2216.66-668.76h5.417v18.386h-5.417Z" transform="translate(-1905.535 810.289)" fill="#b5453b"/>
        </g>
      </g>
      <g id="Сгруппировать_392" data-name="Сгруппировать 392" transform="translate(283.709 -1457.248)" clip-path="url(#clip-path-147)">
        <g id="Сгруппировать_391" data-name="Сгруппировать 391" transform="translate(-311.763 -139.31)" clip-path="url(#clip-path-2)">
          <path id="Контур_329" data-name="Контур 329" d="M2216.66-691.17h2.707v4.361h-2.707Z" transform="translate(-1905.535 829.842)" fill="#eecaab"/>
        </g>
      </g>
      <g id="Сгруппировать_394" data-name="Сгруппировать 394" transform="translate(296.109 -1452.966)" clip-path="url(#clip-path-149)">
        <g id="Сгруппировать_393" data-name="Сгруппировать 393" transform="translate(-324.162 -143.591)" clip-path="url(#clip-path-2)">
          <path id="Контур_332" data-name="Контур 332" d="M2313.93-657.58h5.824v18.349h-5.824Z" transform="translate(-1990.405 800.534)" fill="#b5453b"/>
        </g>
      </g>
      <g id="Сгруппировать_396" data-name="Сгруппировать 396" transform="translate(299.232 -1455.793)" clip-path="url(#clip-path-151)">
        <g id="Сгруппировать_395" data-name="Сгруппировать 395" transform="translate(-327.286 -140.764)" clip-path="url(#clip-path-2)">
          <path id="Контур_335" data-name="Контур 335" d="M2338.43-679.76h2.7v4.367h-2.7Z" transform="translate(-2011.782 819.887)" fill="#eecaab"/>
        </g>
      </g>
      <g id="Сгруппировать_398" data-name="Сгруппировать 398" transform="translate(294.301 -1457.897)" clip-path="url(#clip-path-153)">
        <g id="Сгруппировать_397" data-name="Сгруппировать 397" transform="translate(-322.355 -138.661)" clip-path="url(#clip-path-2)">
          <path id="Контур_338" data-name="Контур 338" d="M2299.75-696.26h5.823v18.35h-5.823Z" transform="translate(-1978.033 834.283)" fill="#b5453b"/>
        </g>
      </g>
      <g id="Сгруппировать_400" data-name="Сгруппировать 400" transform="translate(297.425 -1460.723)" clip-path="url(#clip-path-155)">
        <g id="Сгруппировать_399" data-name="Сгруппировать 399" transform="translate(-325.478 -135.835)" clip-path="url(#clip-path-2)">
          <path id="Контур_341" data-name="Контур 341" d="M2324.25-718.43h2.7v4.366h-2.7Z" transform="translate(-1999.409 853.627)" fill="#eecaab"/>
        </g>
      </g>
      <g id="Сгруппировать_402" data-name="Сгруппировать 402" transform="translate(289.079 -1447.264)" clip-path="url(#clip-path-157)">
        <g id="Сгруппировать_401" data-name="Сгруппировать 401" transform="translate(-317.132 -149.293)" clip-path="url(#clip-path-2)">
          <path id="Контур_344" data-name="Контур 344" d="M2258.78-612.85h12.7v17.741h-12.7Z" transform="translate(-1942.285 761.506)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_404" data-name="Сгруппировать 404" transform="translate(283.367 -1447.264)" clip-path="url(#clip-path-159)">
        <g id="Сгруппировать_403" data-name="Сгруппировать 403" transform="translate(-311.42 -149.293)" clip-path="url(#clip-path-2)">
          <path id="Контур_347" data-name="Контур 347" d="M2213.97-612.85h12.7v17.741h-12.7Z" transform="translate(-1903.188 761.506)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_406" data-name="Сгруппировать 406" transform="translate(269.622 -1379.936)" clip-path="url(#clip-path-161)">
        <g id="Сгруппировать_405" data-name="Сгруппировать 405" transform="translate(-297.675 -216.622)" clip-path="url(#clip-path-2)">
          <path id="Контур_350" data-name="Контур 350" d="M2106.15-84.684h10.894v9.839H2106.15Z" transform="translate(-1809.112 300.668)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_408" data-name="Сгруппировать 408" transform="translate(272.534 -1379.883)" clip-path="url(#clip-path-163)">
        <g id="Сгруппировать_407" data-name="Сгруппировать 407" transform="translate(-300.587 -216.674)" clip-path="url(#clip-path-2)">
          <path id="Контур_353" data-name="Контур 353" d="M2128.99-84.274h3.805v3.7h-3.805Z" transform="translate(-1829.04 300.311)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_410" data-name="Сгруппировать 410" transform="translate(275.43 -1378.543)" clip-path="url(#clip-path-165)">
        <g id="Сгруппировать_409" data-name="Сгруппировать 409" transform="translate(-303.483 -218.014)" clip-path="url(#clip-path-2)">
          <path id="Контур_356" data-name="Контур 356" d="M2151.708-73.76h4.021v5.417h-4.021Z" transform="translate(-1848.863 291.137)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_412" data-name="Сгруппировать 412" transform="translate(277.88 -1379.884)" clip-path="url(#clip-path-167)">
        <g id="Сгруппировать_411" data-name="Сгруппировать 411" transform="translate(-305.933 -216.673)" clip-path="url(#clip-path-2)">
          <path id="Контур_359" data-name="Контур 359" d="M2170.93-84.28h2.636v8.167h-2.636Z" transform="translate(-1865.634 300.316)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_414" data-name="Сгруппировать 414" transform="translate(269.622 -1377.022)" clip-path="url(#clip-path-169)">
        <g id="Сгруппировать_413" data-name="Сгруппировать 413" transform="translate(-297.675 -219.535)" clip-path="url(#clip-path-2)">
          <path id="Контур_362" data-name="Контур 362" d="M2106.15-61.83h5.713v6.63h-5.713Z" transform="translate(-1809.112 280.728)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_416" data-name="Сгруппировать 416" transform="translate(270.517 -1377.022)" clip-path="url(#clip-path-171)">
        <g id="Сгруппировать_415" data-name="Сгруппировать 415" transform="translate(-298.57 -219.535)" clip-path="url(#clip-path-2)">
          <path id="Контур_365" data-name="Контур 365" d="M2113.17-61.83h2.444v2.644h-2.444Z" transform="translate(-1815.237 280.728)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_418" data-name="Сгруппировать 418" transform="translate(120.622 -1379.936)" clip-path="url(#clip-path-173)">
        <g id="Сгруппировать_417" data-name="Сгруппировать 417" transform="translate(-148.675 -216.622)" clip-path="url(#clip-path-2)">
          <path id="Контур_368" data-name="Контур 368" d="M937.3-84.684h10.894v9.839H937.3Z" transform="translate(-789.262 300.668)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_420" data-name="Сгруппировать 420" transform="translate(123.533 -1379.883)" clip-path="url(#clip-path-175)">
        <g id="Сгруппировать_419" data-name="Сгруппировать 419" transform="translate(-151.587 -216.674)" clip-path="url(#clip-path-2)">
          <path id="Контур_371" data-name="Контур 371" d="M960.14-84.274h3.805v3.7H960.14Z" transform="translate(-809.191 300.311)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_422" data-name="Сгруппировать 422" transform="translate(126.429 -1378.543)" clip-path="url(#clip-path-177)">
        <g id="Сгруппировать_421" data-name="Сгруппировать 421" transform="translate(-154.483 -218.014)" clip-path="url(#clip-path-2)">
          <path id="Контур_374" data-name="Контур 374" d="M982.858-73.76h4.022v5.417h-4.022Z" transform="translate(-829.012 291.137)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_424" data-name="Сгруппировать 424" transform="translate(128.88 -1379.884)" clip-path="url(#clip-path-179)">
        <g id="Сгруппировать_423" data-name="Сгруппировать 423" transform="translate(-156.933 -216.673)" clip-path="url(#clip-path-2)">
          <path id="Контур_377" data-name="Контур 377" d="M1002.08-84.28h2.636v8.167h-2.636Z" transform="translate(-845.784 300.316)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_426" data-name="Сгруппировать 426" transform="translate(120.622 -1377.022)" clip-path="url(#clip-path-181)">
        <g id="Сгруппировать_425" data-name="Сгруппировать 425" transform="translate(-148.675 -219.535)" clip-path="url(#clip-path-2)">
          <path id="Контур_380" data-name="Контур 380" d="M937.3-61.83h5.713v6.63H937.3Z" transform="translate(-789.262 280.728)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_428" data-name="Сгруппировать 428" transform="translate(121.517 -1377.022)" clip-path="url(#clip-path-183)">
        <g id="Сгруппировать_427" data-name="Сгруппировать 427" transform="translate(-149.57 -219.535)" clip-path="url(#clip-path-2)">
          <path id="Контур_383" data-name="Контур 383" d="M944.32-61.83h2.444v2.644H944.32Z" transform="translate(-795.387 280.728)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_430" data-name="Сгруппировать 430" transform="translate(280.148 -1439.483)" clip-path="url(#clip-path-185)">
        <g id="Сгруппировать_429" data-name="Сгруппировать 429" transform="translate(-308.201 -157.075)" clip-path="url(#clip-path-2)">
          <path id="Контур_386" data-name="Контур 386" d="M2188.72-551.81h11.111v10.071H2188.72Z" transform="translate(-1881.156 708.247)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_432" data-name="Сгруппировать 432" transform="translate(282.945 -1439.143)" clip-path="url(#clip-path-187)">
        <g id="Сгруппировать_431" data-name="Сгруппировать 431" transform="translate(-310.998 -157.415)" clip-path="url(#clip-path-2)">
          <path id="Контур_389" data-name="Контур 389" d="M2210.66-549.142h3.9v3.645h-3.9Z" transform="translate(-1900.3 705.92)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_434" data-name="Сгруппировать 434" transform="translate(286.099 -1438.078)" clip-path="url(#clip-path-189)">
        <g id="Сгруппировать_433" data-name="Сгруппировать 433" transform="translate(-314.152 -158.479)" clip-path="url(#clip-path-2)">
          <path id="Контур_392" data-name="Контур 392" d="M2235.405-540.79h4.157v5.463h-4.157Z" transform="translate(-1921.89 698.632)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_436" data-name="Сгруппировать 436" transform="translate(288.374 -1439.442)" clip-path="url(#clip-path-191)">
        <g id="Сгруппировать_435" data-name="Сгруппировать 435" transform="translate(-316.427 -157.115)" clip-path="url(#clip-path-2)">
          <path id="Контур_395" data-name="Контур 395" d="M2253.25-551.49h2.885v8.248h-2.885Z" transform="translate(-1937.46 707.968)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_438" data-name="Сгруппировать 438" transform="translate(280.148 -1436.072)" clip-path="url(#clip-path-193)">
        <g id="Сгруппировать_437" data-name="Сгруппировать 437" transform="translate(-308.201 -160.486)" clip-path="url(#clip-path-2)">
          <path id="Контур_398" data-name="Контур 398" d="M2188.72-525.05h6.069v6.659h-6.069Z" transform="translate(-1881.156 684.898)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_440" data-name="Сгруппировать 440" transform="translate(281.057 -1436.121)" clip-path="url(#clip-path-195)">
        <g id="Сгруппировать_439" data-name="Сгруппировать 439" transform="translate(-309.11 -160.436)" clip-path="url(#clip-path-2)">
          <path id="Контур_401" data-name="Контур 401" d="M2195.85-525.44h2.539v2.643h-2.539Z" transform="translate(-1887.378 685.239)" fill="#f1f7fd"/>
        </g>
      </g>
      <g id="Сгруппировать_442" data-name="Сгруппировать 442" transform="translate(262.741 -1436.898)" clip-path="url(#clip-path-197)">
        <g id="Сгруппировать_441" data-name="Сгруппировать 441" transform="translate(-290.794 -159.66)" clip-path="url(#clip-path-2)">
          <path id="Контур_404" data-name="Контур 404" d="M2052.17-531.53h8.9v7.3h-8.9Z" transform="translate(-1762.013 690.552)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_444" data-name="Сгруппировать 444" transform="translate(264.912 -1436.608)" clip-path="url(#clip-path-199)">
        <g id="Сгруппировать_443" data-name="Сгруппировать 443" transform="translate(-292.965 -159.949)" clip-path="url(#clip-path-2)">
          <path id="Контур_407" data-name="Контур 407" d="M2069.2-529.26h3.308v2.855H2069.2Z" transform="translate(-1776.872 688.571)" fill="#c4d9e8"/>
        </g>
      </g>
      <g id="Сгруппировать_446" data-name="Сгруппировать 446" transform="translate(267.356 -1435.951)" clip-path="url(#clip-path-201)">
        <g id="Сгруппировать_445" data-name="Сгруппировать 445" transform="translate(-295.41 -160.607)" clip-path="url(#clip-path-2)">
          <path id="Контур_410" data-name="Контур 410" d="M2088.377-524.1h3.51v4.1h-3.51Z" transform="translate(-1793.605 684.07)" fill="#dfebf5"/>
        </g>
      </g>
      <g id="Сгруппировать_448" data-name="Сгруппировать 448" transform="translate(269.122 -1436.872)" clip-path="url(#clip-path-203)">
        <g id="Сгруппировать_447" data-name="Сгруппировать 447" transform="translate(-297.176 -159.685)" clip-path="url(#clip-path-2)">
          <path id="Контур_413" data-name="Контур 413" d="M2102.23-531.33h2.523v5.977h-2.523Z" transform="translate(-1805.692 690.378)" fill="#c4d9e8"/>
        </g>
      </g>
      <g id="Сгруппировать_450" data-name="Сгруппировать 450" transform="translate(262.741 -1434.5)" clip-path="url(#clip-path-205)">
        <g id="Сгруппировать_449" data-name="Сгруппировать 449" transform="translate(-290.794 -162.058)" clip-path="url(#clip-path-2)">
          <path id="Контур_416" data-name="Контур 416" d="M2052.17-512.72h4.992v4.9h-4.992Z" transform="translate(-1762.013 674.14)" fill="#dfebf5"/>
        </g>
      </g>
      <g id="Сгруппировать_452" data-name="Сгруппировать 452" transform="translate(263.446 -1434.544)" clip-path="url(#clip-path-207)">
        <g id="Сгруппировать_451" data-name="Сгруппировать 451" transform="translate(-291.499 -162.013)" clip-path="url(#clip-path-2)">
          <path id="Контур_419" data-name="Контур 419" d="M2057.7-513.07h2.255v2.2H2057.7Z" transform="translate(-1766.838 674.446)" fill="#dfebf5"/>
        </g>
      </g>
      <g id="Сгруппировать_454" data-name="Сгруппировать 454" transform="translate(151.016 -1476.128)" clip-path="url(#clip-path-209)">
        <g id="Сгруппировать_453" data-name="Сгруппировать 453" transform="translate(-179.069 -120.43)" clip-path="url(#clip-path-2)">
          <path id="Контур_422" data-name="Контур 422" d="M1175.731-839.276h14.275v5.757h-14.275Z" transform="translate(-997.299 959.068)" fill="#ffb17c"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "OfficesImage"
}
</script>

<style scoped>

</style>
