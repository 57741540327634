<template>
  <!--begin::Add Edit Modal-->
  <!--    @show="resetModal"-->
  <!--    @ok="handleOk"-->
  <b-modal
      id="modal-add-edit"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ isEdit ? $t("MANAGEMENT.EDIT_OFFICE_TITLE") : $t("MANAGEMENT.ADD_OFFICE_TITLE") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label-for="name-input"
          :invalid-feedback="$t('MANAGEMENT.NAME_OFFICE_INVALID_FEEDBACK')"
          :state="nameState"
      >
        <template slot="label">{{ $t('MANAGEMENT.NAME_OFFICE_INPUT_TITLE') }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-input
            id="name-input"
            :placeholder="$t('MANAGEMENT.NAME_OFFICE_PLACEHOLDER')"
            v-model="form.name"
            :state="nameState"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          v-if="form.address"
          label-for="country-input"
          :invalid-feedback="$t('MANAGEMENT.COUNTRY_INVALID_FEEDBACK')"
          :state="countryState"
      >
        <template slot="label">{{ $t('MANAGEMENT.COUNTRY_SELECT_TITLE') }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="country-input"
            v-model="form.address.country"
            :state="countryState"
            @change="selectedCountry"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="country.id" v-for="country in countryCityList" :key="country.id">{{ country.country }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
          v-if="form.address"
          label-for="city-input"
          :invalid-feedback="$t('MANAGEMENT.CITY_INVALID_FEEDBACK')"
          :state="cityState"
      >
        <template slot="label">{{ $t("MANAGEMENT.CITY_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="city-input"
            v-model="form.address.city"
            :state="cityState"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="city.id" v-for="city in citiesOfCountry" :key="city.id">{{ city.name }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
          v-if="form.address"
          label-for="address-input"
          :invalid-feedback="$t('MANAGEMENT.ADDRESS_INVALID_FEEDBACK')"
          :state="addressState"
      >
        <template slot="label">{{ $t("MANAGEMENT.ADDRESS_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-input
            id="address-input"
            :placeholder="$t('MANAGEMENT.ADDRESS_PLACEHOLDER')"
            v-model="form.address.rest"
            :state="addressState"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          label-for="timezone-input"
          :invalid-feedback="$t('MANAGEMENT.TIMEZONE_INVALID_FEEDBACK')"
          :state="timezoneState"
      >
        <template slot="label">{{ $t("MANAGEMENT.TIMEZONE_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="timezone-input"
            v-model="form.timezone"
            :state="timezoneState"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="zone.id" v-for="zone in timezonesList" :key="zone.id">{{ zone.name }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              v-if="!isEdit"
              class="font-weight-bolder mr-2"
              variant="secondary"
              @click="handleOkAndGo">
            {{ $t("MANAGEMENT.ADD_SETTING_FLOOR") }}
          </b-button>
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ isEdit ? $t('MANAGEMENT.SAVE_BUTTON') : $t('MANAGEMENT.ADD_BUTTON') }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
  <!--end::Add Edit Modal-->
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_COUNTRY_CITY_LIST, GET_OFFICE,
  GET_OFFICES_LIST,
  GET_TIMEZONES_LIST, POST_NEW_OFFICE,
  PUT_OFFICE
} from "@/core/services/store/management.module";

export default {
  name: "AddEditOfficeModal",
  mounted() {
    if (this.onPage === 'office') {
      this.$store.dispatch(GET_COUNTRY_CITY_LIST, { params: { is_use:  0 } });
      this.$store.dispatch(GET_TIMEZONES_LIST);
    }
  },
  props: {
    citiesOfCountry: {
      type: Array
    },
    onPage: {
      type: String
    },
    form: {
      type: Object,
      default: ()=> {
        return {
          name: '',
          address: {
            country: null,
            city: null,
            rest: ''
          },
          timezone: null
        }
      }
      // required: true
    },
    isEdit: {
      type: Boolean,
      default: false
      // required: true
    }
  },
  data() {
    return {
      nameState: null,
      countryState: null,
      cityState: null,
      addressState: null,
      timezoneState: null
    }
  },
  computed: {
    ...mapGetters([
      "countryCityList",
      "timezonesList"
    ])
  },
  methods: {
    selectedCountry() {
      this.$emit('changeSelectedCountry', this.form.address.country);
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      this.form.address.country === null ? this.countryState = false : this.countryState = true;
      this.form.address.city === null ? this.cityState = false : this.cityState = true;
      this.addressState = valid;
      this.form.timezone === null ? this.timezoneState = false : this.timezoneState = true
      return valid;
    },
    resetModal() {
      if (this.onPage === 'offices') {
        let emptyForm = {
          name: '',
          address: {
            country: null,
            city: null,
            rest: ''
          },
          timezone: null
        };
        this.$emit('changeForm', emptyForm);
      }
      this.$emit('changeIsEdit', false);
      this.nameState = null;
      this.countryState = null;
      this.cityState = null;
      this.addressState = null;
      this.timezoneState = null;
      if (this.onPage === 'offices') this.$store.dispatch(GET_OFFICES_LIST)
      else this.$store.dispatch(GET_OFFICE, this.$route.params.id);
      this.$bvModal.hide('modal-add-edit');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleOkAndGo(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit('nextStep');
    },
    handleSubmit(next) {
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.isEdit) {
        let form = {
          name: this.form.name,
          address: {
            country: this.form.address.country,
            city: this.form.address.city,
            rest: this.form.address.rest
          },
          timezone: this.form.timezone
        }
        this.$store.dispatch(PUT_OFFICE, { form, id: this.form.id })
            .then(() => {
              if (this.onPage === 'offices') this.$store.dispatch(GET_OFFICES_LIST)
              else this.$store.dispatch(GET_OFFICE, this.$route.params.id);
            });
      } else this.$store.dispatch(POST_NEW_OFFICE, this.form)
          .then(res => {
            this.$store.dispatch(GET_OFFICES_LIST)
            if (next) this.$router.push({ path: `offices/office/${res.id}` })
          });
      this.$nextTick(() => {
        this.$bvModal.hide('modal-add-edit');
      })
    },
  }
}
</script>

<style scoped>

</style>
